<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Offers</div>
          <div>
            <ButtonComp
              class="me-2"
              icon="icon-price-tag"
              label="Set Promotional Banner"
              outlined
              @click="handlePromotionalBannerShow"
            />
            <button
              v-if="isAuthorized(permissions.offer_create)"
              class="btn btn-sm btn-primary p-2"
              @click="handleShowHideAction"
            >
              <i class="icon-add" />
              Add New
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="offer-page-content" v-loading="state.loading">

            <ItemTable
              :columns="state.columnMap"
              :data="state.offer"
              :show-actions="isAuthorized(permissionsGroup.offer)"
            >
              <template #amount="{item}">
                {{ renderAmount(item.amount, item) }}
              </template>
              <template #min_order="{item}">
                {{ currency(item.min_order) }}
              </template>

              <template #first_time="{item}">
                {{ item.first_time ? 'YES' : 'NO' }}
              </template>

              <template #once="{item}">
                {{ item.once ? 'YES' : 'NO' }}
              </template>

              <template #to="{item}">
                {{ formatDate(item.to) }}
              </template>

              <template #show="{item}">
                <span v-html="renderShow(item.show)" />
              </template>

              <template #status="{item}">
                <span v-html="renderEnabledBadge(item.status)" />
              </template>

              <template #action="{item}" v-if="isAuthorized(permissionsGroup.offer)">
                <EditButton
                  v-if="isAuthorized(permissions.offer_update)"
                  @click="handleSelectedOrderAction(item, LIST_ITEM_ACTION_UPDATE)"
                />

                <DeleteButton
                  v-if="isAuthorized(permissions.offer_delete)"
                  @click="handleSelectedOrderAction(item, LIST_ITEM_ACTION_DELETE)"
                />
              </template>

            </ItemTable>
          </div>
        </div>
      </div>
    </div>

    <OfferAction
      :show="state.showAction"
      :itemToUpdate="state.selectedItem"
      @hide="handleShowHideAction"
      @updated="handleShowHideAction"
    />

    <PromotionalOfferAction
      v-model:show="state.showPromotionalBanner"
      @updated="state.showPromotionalBanner = !state.showPromotionalBanner"
    />

  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from 'vue';
import { formatDate, isAuthorized, renderEnabledBadge } from '@/utils/Helper';
import { useStore } from 'vuex';
import ItemTable from '@/components/Util/ItemTable';
import { currency, renderStatus } from '@/Mixins/appHelper';
import ButtonComp from '@/components/Util/ButtonComp';
import { LIST_ITEM_ACTION_DELETE, LIST_ITEM_ACTION_UPDATE, } from '@/utils/constants';
import OfferAction from '@/components/Offer/OfferAction';
import Toaster from '@/utils/Toaster';
import permissions, { permissionsGroup } from '@/utils/permissions';
import EditButton from '@/components/Util/ListActions/EditButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import SweetAlert from '@/utils/SweetAlert';
import PromotionalOfferAction from '@/components/Offer/PromotionalOfferAction';

export default defineComponent({
  name: 'Offer',
  components: { PromotionalOfferAction, DeleteButton, EditButton, OfferAction, ButtonComp, ItemTable },
  setup () {
    const store = useStore();

    const state = reactive({
      columnMap: [
        { field: 'type', header: 'Type', sortable: true },
        { field: 'code', header: 'Code' },
        { field: 'amount', header: 'Amount', sortable: true },
        { field: 'min_order', header: 'Order Min.', sortable: true },
        { field: 'first_time', header: 'First Time', sortable: true },
        { field: 'once', header: 'Once', sortable: true },
        { field: 'to', header: 'Till', sortable: true },
        { field: 'sort', header: 'Sort' },
        { field: 'show', header: 'Show', sortable: true },
        { field: 'status', header: 'Status', sortable: true },
      ],

      offer: computed(() => store.getters['offer/offer']),
      loading: computed(() => store.state.offer.loading),
      selectedItem: {},
      showAction: false,

      showPromotionalBanner: false

    });

    const getOffer = async () => {

      // if state has already data ignore reloading
      const offers = store.getters['offer/offer'];
      if (offers.length) return;

      // load data to store
      await store.dispatch('offer/getOffer');

    };

    const deleteOffer = async () => {

      try {
        let response = await store.dispatch('offer/deleteOffer', state.selectedItem.id);

        Toaster.successAlt({
          message: response.data.message || 'Offer Successfully Deleted'
        });

      } catch (e) {

        Toaster.error({
          message: e.message || 'Something went wrong'
        });

      }
    };

    const renderAmount = (amount, row) => {

      if (!row) return;

      return row.fixed
        ? currency(amount)
        : `${amount}%`
        ;
    };

    const renderShow = (showStatus) => {
      return renderStatus(showStatus ? 'shown' : 'hidden', 'fs15');
    };

    const handleSelectedOrderAction = (item, action) => {

      if (!isAuthorized(permissionsGroup.offer)) return;

      state.selectedItem = item;

      if (action === LIST_ITEM_ACTION_DELETE) {

        if (!isAuthorized(permissions.offer_delete)) return;

        SweetAlert.confirmError({
          text: `This will permanently Delete this '${item.type}'`,
          preConfirm: deleteOffer
        });

        return;
      }

      if (!isAuthorized(permissions.offer_update)) return;
      state.showAction = true;
    };

    const handleShowHideAction = () => {
      state.selectedItem = {};
      state.showAction = !state.showAction;
    };

    const handlePromotionalBannerShow = () => {
      state.showPromotionalBanner = true;
    };

    onMounted(async () => {
      await getOffer();
    });

    return {
      state,
      handleShowHideAction,
      formatDate,
      renderShow,
      currency,
      renderAmount,
      renderEnabledBadge,
      handleSelectedOrderAction,
      LIST_ITEM_ACTION_DELETE,
      LIST_ITEM_ACTION_UPDATE,
      permissions,
      permissionsGroup,
      isAuthorized,
      handlePromotionalBannerShow,
    };
  },
});
</script>

<style scoped>

</style>
